import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Modal from "../../components/modal/modal"

import cn from "./single-book.module.scss"

const SingleBookTemplate = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [pageIndex, setPageIndex] = useState(null)

  const book = data.markdownRemark.frontmatter

  const modalImage = () => {
    if (!pageIndex) {
      return book.coverimage.childImageSharp.fluid
    } else {
      return book.innerPages[pageIndex].childImageSharp.fluid
    }
  }

  const clickEventHandler = e => {
    if (!isVisible) {
      setIsVisible(true)
      setPageIndex(e.target.closest("div").parentNode.dataset.page)
    } else {
      setIsVisible(false)
    }
  }

  const escapeEventHandler = e => {
    if (e.code === "Escape") {
      setIsVisible(false)
    }
  }

  const openEventHandler = e => {
    if (e.key === "Enter" || e.key === "Space") {
      e.preventDefault()
      setPageIndex(e.target.dataset.page)
      setIsVisible(true)
    }
  }

  return (
    <Layout>
      <SEO title={book.title} />
      <div className={cn.content}>
        <div
          onClick={clickEventHandler}
          onKeyDown={openEventHandler}
          className={cn.images}
          tabIndex="0"
          role="button"
        >
          <Img fluid={book.coverimage.childImageSharp.fluid} alt="Book cover" />
        </div>
        <div className={cn.info}>
          <h1>{book.title}</h1>
          <pre className={cn.bookInfo}>{book.bookinfo}</pre>
          <p>{book.description}</p>
        </div>
        {book.innerPages &&
          book.innerPages.map((page, i) => {
            return (
              <div
                onClick={clickEventHandler}
                onKeyDown={openEventHandler}
                className={cn.images}
                data-page={i}
                tabIndex="0"
                role="button"
                key={page.id}
              >
                <Img fluid={page.childImageSharp.fluid} alt={`Page ${i}`} />
              </div>
            )
          })}
      </div>
      {isVisible && (
        <Modal clicked={clickEventHandler} keyDown={escapeEventHandler}>
          <Img
            fluid={modalImage()}
            style={{ width: "90%", height: "90%" }}
            imgStyle={{ objectFit: "contain" }}
            alt="xxx"
          />
        </Modal>
      )}
    </Layout>
  )
}

SingleBookTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SingleBookTemplate

export const pageQuery = graphql`
  query SingleBookPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        coverimage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        bookinfo
        description
        templateKey
        title
        innerPages {
          id
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
